<template>
    <Radar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartID"
        :dataset-id-key="datasetIDKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height" />
  </template>
  
  <script>
  import { Radar } from 'vue-chartjs/legacy'
  import { getColors } from '~helpers';
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    RadialLinearScale
  } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, RadialLinearScale)
  
  export default {
    name: 'RadarChart',
    components: {
      Radar
    },
    data() {
      return {
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    computed: {
      chartData() {
          return {
              labels: this.labels,
              datasets: this.values.map((x, ind) => {
                return {
                    label: x.label,
                    backgroundColor: getColors(ind),
                    borderColor: getColors(ind),
                    pointBackgroundColor: getColors(ind),
                    pointBorderColor: getColors(ind),
                    data: x.values
                }})
          };
      }
    },
    props: {
      chartID: {
        type: String,
        default: 'pie-chart'
      },
      datasetIDKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 200
      },
      height: {
        type: Number,
        default: 200
      },
      labels: {
          type: Array,
          default: () => []
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      },
      values: { //[{ label, values[] }, { label, values[] }]
          type: Array,
          default: () => []
      }
    }
  }
  </script>